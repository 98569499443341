import { PERMISSIONS } from "../../constants/permissions";
import { USER_TYPES } from "../../constants/user-types";
import Icons from "../../icons/sidebar";

export interface IGroupMenu extends IMenuItem {
  isGroup?: boolean;
  menuItems?: IMenuItem[];
  permissions?: string[];
  users?: string[];
}

export interface IMenuItem {
  title: string;
  Icon?: any;
  path: string;
  subMenu?: IMenuItem[] | IGroupMenu[];
  permissions?: string[];
  users?: string[];
}

const MenuList: IMenuItem[] = [
  {
    title: "Dashboard",
    Icon: Icons.SpeedIcon,
    path: "/dashboard",
    users: [
      USER_TYPES.INSTITUTION_ADMIN,
      USER_TYPES.STAFF,
      USER_TYPES.SYSTEM_USER,
      USER_TYPES.MASTER_ADMIN,
    ],
  },

  {
    title: "Clients",
    Icon: Icons.GroupsIcon,
    path: "/clients",
    users: [USER_TYPES.INSTITUTION_ADMIN, USER_TYPES.STAFF],
    permissions: [PERMISSIONS.VIEW_CLIENTS],
    subMenu: [
      {
        path: "/clients/individual",
        title: "Individual",
      },
      {
        path: "/clients/institution",
        title: "Institution",
      },
      {
        path: "/clients/group",
        title: "Group",
      },
    ],
  },

  {
    title: "Loans",
    Icon: Icons.PaidIcon,
    path: "/loans",
    users: [USER_TYPES.INSTITUTION_ADMIN, USER_TYPES.STAFF],
    permissions: [PERMISSIONS.VIEW_LOANS],
    subMenu: [
      {
        path: "/loans/applications",
        title: "Applications",
        permissions: [
          PERMISSIONS.ADD_LOANS,
          PERMISSIONS.APPROVE_LOANS,
          PERMISSIONS.DECLINE_LOANS,
        ],
      },
      {
        path: "/loans/disbursement",
        title: "Pending Disbursement",
        permissions: [PERMISSIONS.DISBURSE_LOANS],
      },
      {
        path: "/loans/active",
        title: "Active Loans",
      },
      {
        path: "/loans/declined",
        title: "Declined",
      },
      {
        path: "/loans/closed",
        title: "Closed",
      },
      {
        path: "/loans/written-off-loans",
        title: "Written Off Loans",
      },
      {
        path: "/loans/calculator",
        title: "Loan Calculator",
      },
      {
        path: "/loans/collateral",
        title: "Collateral  Register",
        permissions: [PERMISSIONS.VIEW_LOAN_COLLATERALS],
      },
    ],
  },

  {
    title: "Savings",
    Icon: Icons.SavingsIcon,
    path: "/savings",
    users: [USER_TYPES.INSTITUTION_ADMIN, USER_TYPES.STAFF],
    permissions: [PERMISSIONS.MANAGE_SAVINGS],
    subMenu: [
      {
        path: "/savings/deposit",
        title: "Register Deposit",
        permissions: [PERMISSIONS.CLIENT_ADD_DEPOSIT],
      },
      {
        path: "/savings/withdraw",
        title: "Register Withdraw",
        permissions: [PERMISSIONS.CLIENT_ADD_WITHDRAWAL],
      },
      {
        path: "/savings/deposit-transactions",
        title: "Deposit Transactions",
        permissions: [PERMISSIONS.CLIENT_ADD_DEPOSIT],
      },
      {
        path: "/savings/withdraw-transactions",
        title: "Withdraw Transactions",
        permissions: [PERMISSIONS.CLIENT_ADD_WITHDRAWAL],
      },
      {
        path: "/savings/fixed-deposit",
        title: "Fixed Deposit Calculator",
        permissions: [PERMISSIONS.CLIENT_ADD_FIXEDDEPOSIT],
      },
      {
        path: "/savings/disburse-interest",
        title: "Disburse Interest",
        permissions: [PERMISSIONS.DISRBUSE_INTEREST],
      },
    ],
  },

  {
    title: "Shares",
    Icon: Icons.BalanceIcon,
    path: "/shares",
    users: [USER_TYPES.INSTITUTION_ADMIN, USER_TYPES.STAFF],
    permissions: [PERMISSIONS.VIEW_SHARES],
    subMenu: [
      {
        path: "/shares/register",
        title: "Shares Register",
        permissions: [PERMISSIONS.VIEW_SHARES],
      },
      {
        path: "/shares/share-purchase",
        title: "Purchase Shares",
        permissions: [PERMISSIONS.ADD_PURCHASE_SHARES],
      },
      {
        path: "/shares/share-transfer",
        title: "Transfer Shares",
        permissions: [PERMISSIONS.ADD_TRANSFER_SHARES],
      },
      {
        path: "/shares/purchase-transactions",
        title: "Share Purchases",
        permissions: [PERMISSIONS.VIEW_PURCHASE_SHARES],
      },
      {
        path: "/shares/transfer-transactions",
        title: "Share Transfers",
        permissions: [PERMISSIONS.VIEW_TRANSFER_SHARES],
      },
      {
        path: "/shares/share-sell",
        title: "Sell Shares",
        permissions: [],
      },
      {
        path: "/shares/dividends",
        title: "Dividends",
        permissions: [PERMISSIONS.VIEW_TRANSFER_SHARES],
      },
    ],
  },
  {
    title: "Assets",
    Icon: Icons.WebAssetIcon,
    path: "/assets",
    users: [USER_TYPES.INSTITUTION_ADMIN, USER_TYPES.STAFF],
    permissions: [
      PERMISSIONS.VIEW_PHYSICAL_ASSETS,
      PERMISSIONS.VIEW_NON_PHYSICAL_ASSETS,
    ],
    subMenu: [
      {
        path: "/assets/physical",
        title: "Physical Assets",
        permissions: [PERMISSIONS.VIEW_PHYSICAL_ASSETS],
      },
      {
        path: "/assets/non-physcial",
        title: "Non Physical",
        permissions: [PERMISSIONS.VIEW_NON_PHYSICAL_ASSETS],
      },
    ],
  },

  {
    title: "Accounting",
    Icon: Icons.CalculateIcon,
    path: "/accounting",
    users: [USER_TYPES.INSTITUTION_ADMIN, USER_TYPES.STAFF],
    permissions: [
      PERMISSIONS.VIEW_ACCOUNTS,
      PERMISSIONS.VIEW_TILL_SHEET,
      PERMISSIONS.VIEW_SUPPLIERS,
      PERMISSIONS.VIEW_RECEIVABLES,
      PERMISSIONS.VIEW_DEBTORS,
      PERMISSIONS.VIEW_PAYABLES,
      PERMISSIONS.VIEW_CREDITORS,
      PERMISSIONS.VIEW_CASH_TRANSFERS,
      PERMISSIONS.ADD_CASH_TRANSFERS,
      PERMISSIONS.ADD_STAFF_CASH_ACCOUNTS,
      PERMISSIONS.ADD_STAFF_BANK_ACCOUNTS,
      PERMISSIONS.ADD_STAFF_SAFE_ACCOUNTS,
    ],
    subMenu: [
      {
        path: "/accounting/chart-of-accounts",
        title: "Accounting",
        permissions: [PERMISSIONS.VIEW_CHART_OF_ACCOUNTS],
      },
      {
        path: "/accounting/transaction-accounts",
        title: "Transaction Accounts",
        permissions: [
          PERMISSIONS.ADD_STAFF_CASH_ACCOUNTS,
          PERMISSIONS.ADD_STAFF_BANK_ACCOUNTS,
          PERMISSIONS.ADD_STAFF_SAFE_ACCOUNTS,
          PERMISSIONS.VIEW_STAFF_SAFE_ACCOUNTS,
          PERMISSIONS.VIEW_STAFF_BANK_ACCOUNTS,
          PERMISSIONS.VIEW_STAFF_CASH_ACCOUNTS,
          PERMISSIONS.VIEW_STAFF_BANK_ACCOUNT_STATEMENT,
          PERMISSIONS.VIEW_STAFF_SAFE_ACCOUNT_STATEMENT,
        ],
      },
      {
        path: "/accounting/cash-transfers",
        title: "Cash Transfers",
        permissions: [
          PERMISSIONS.VIEW_CASH_TRANSFERS,
          PERMISSIONS.ADD_CASH_TRANSFERS,
        ],
      },
      {
        path: "/accounting/payables",
        title: "Payables",
        permissions: [PERMISSIONS.VIEW_PAYABLES, PERMISSIONS.VIEW_CREDITORS],
      },
      {
        path: "/accounting/receivables",
        title: "Recievables",
        permissions: [PERMISSIONS.VIEW_RECEIVABLES, PERMISSIONS.VIEW_DEBTORS],
      },
      {
        path: "/accounting/suppliers",
        title: "Suppliers",
        permissions: [PERMISSIONS.VIEW_SUPPLIERS],
      },
      {
        path: "/accounting/staff-till-sheet",
        title: "Staff Till Sheet",
        permissions: [PERMISSIONS.VIEW_TILL_SHEET],
      },
    ],
  },
  {
    title: "SMS Banking",
    Icon: Icons.SmsIcon,
    path: "/sms-banking",
    users: [
      USER_TYPES.INSTITUTION_ADMIN,
      USER_TYPES.STAFF,
      USER_TYPES.SYSTEM_USER,
    ],
    permissions: [PERMISSIONS.MANAGE_SMS_SETTINGS, PERMISSIONS.VIEW_OUTBOX],
    subMenu: [
      {
        path: "/sms-banking/settings",
        title: "Settings",
        permissions: [PERMISSIONS.MANAGE_SMS_SETTINGS],
      },
      {
        path: "/sms-banking/outbox",
        title: "Outbox",
        permissions: [PERMISSIONS.VIEW_OUTBOX],
      },
    ],
  },
  {
    title: "Know Your Customer",
    Icon: Icons.PersonSearchIcon,
    path: "/kyc",
    users: [USER_TYPES.INSTITUTION_ADMIN, USER_TYPES.STAFF],
    permissions: [],
    subMenu: [
      {
        path: "/kyc/services",
        title: "KYC Services",
        permissions: [],
      },
      {
        path: "/kyc/checks",
        title: "KYC Check",
        permissions: [],
      },
    ],
  },
  {
    title: "Workflow Requests",
    Icon: Icons.SchemaIcon,
    path: "/workflow-requests",
    users: [USER_TYPES.INSTITUTION_ADMIN, USER_TYPES.STAFF],
    permissions: [
      PERMISSIONS.VIEW_WORKFLOW_REQUEST,
      PERMISSIONS.ADD_WORKFLOW_REQUEST,
    ],
    subMenu: [
      {
        path: "/workflow-requests/transaction-reversal",
        title: "Transaction Reversal",
        permissions: [
          PERMISSIONS.VIEW_WORKFLOW_REQUEST,
          PERMISSIONS.ADD_WORKFLOW_REQUEST,
        ],
      },
      {
        path: "/workflow-requests/loan-write-off",
        title: "Loan Write Off",
        permissions: [
          PERMISSIONS.VIEW_WORKFLOW_REQUEST,
          PERMISSIONS.ADD_WORKFLOW_REQUEST,
        ],
      },
      {
        path: "/workflow-requests/loan-disbursement",
        title: "Loan Disbursement",
        permissions: [
          PERMISSIONS.VIEW_WORKFLOW_REQUEST,
          PERMISSIONS.ADD_WORKFLOW_REQUEST,
        ],
      },
      {
        path: "/workflow-requests/loan-disbursement-reversal",
        title: "Reverse Disbursement Request",
        permissions: [
          PERMISSIONS.VIEW_WORKFLOW_REQUEST,
          PERMISSIONS.ADD_WORKFLOW_REQUEST,
        ],
      },
      {
        path: "/workflow-requests/loan-approval",
        title: "Loan Approval",
        permissions: [
          PERMISSIONS.VIEW_WORKFLOW_REQUEST,
          PERMISSIONS.ADD_WORKFLOW_REQUEST,
        ],
      },
      {
        path: "/workflow-requests/loan-topup",
        title: "Loan Topup",
        permissions: [
          PERMISSIONS.VIEW_WORKFLOW_REQUEST,
          PERMISSIONS.ADD_WORKFLOW_REQUEST,
        ],
      },
      {
        path: "/workflow-requests/share_purchase_reversal",
        title: "Share Purchase Reversal",
        permissions: [
          PERMISSIONS.VIEW_WORKFLOW_REQUEST,
          PERMISSIONS.ADD_WORKFLOW_REQUEST,
        ],
      },
      {
        path: "/workflow-requests/receivable_reversal",
        title: "Receivable Reversal",
        permissions: [
          PERMISSIONS.VIEW_WORKFLOW_REQUEST,
          PERMISSIONS.ADD_WORKFLOW_REQUEST,
        ],
      },
      {
        path: "/workflow-requests/payable_reversal",
        title: "Payable Reversal",
        permissions: [
          PERMISSIONS.VIEW_WORKFLOW_REQUEST,
          PERMISSIONS.ADD_WORKFLOW_REQUEST,
        ],
      },
      {
        path: "/workflow-requests/asset-purchase-reversal",
        title: "Asset Purchase Reversal",
        permissions: [
          PERMISSIONS.VIEW_WORKFLOW_REQUEST,
          PERMISSIONS.ADD_WORKFLOW_REQUEST,
        ],
      },
    ],
  },
  {
    title: "Data Importer",
    Icon: Icons.DriveFolderUploadIcon,
    path: "/data-importer",
    users: [USER_TYPES.INSTITUTION_ADMIN, USER_TYPES.STAFF],
    permissions: [PERMISSIONS.VIEW_BATCH_IMPORT],
    subMenu: [
      {
        path: "/data-importer/import-clients",
        title: "Clients",
        permissions: [PERMISSIONS.IMPORT_CLIENTS],
      },
      {
        path: "/data-importer/import-loans",
        title: "Loans",
        permissions: [PERMISSIONS.IMPORT_LOANS],
      },
      {
        path: "/data-importer/import-loan-payments",
        title: "Loan Payments",
        permissions: [PERMISSIONS.IMPORT_LOANS],
      },
      {
        path: "/data-importer/import-savings-transactions",
        title: "Savings Transactions",
        permissions: [PERMISSIONS.IMPORT_CLIENT_ACCOUNTS],
      },
      {
        path: "/data-importer/import-client-accounts",
        title: "Client Accounts",
        permissions: [PERMISSIONS.IMPORT_CLIENT_ACCOUNTS],
      },
      {
        path: "/data-importer/import-shares",
        title: "Shares",
        permissions: [PERMISSIONS.IMPORT_SHARES],
      },
      {
        path: "/data-importer/import-coa",
        title: "Chart of Accounts",
        permissions: [PERMISSIONS.IMPORT_CHART_OF_ACCOUNTS],
      },
    ],
  },
  {
    title: "Reports",
    Icon: Icons.StackedLineChartIcon,
    path: "/reports",
    users: [
      USER_TYPES.INSTITUTION_ADMIN,
      USER_TYPES.STAFF,
      USER_TYPES.MASTER_ADMIN,
    ],
    permissions: [
      PERMISSIONS.REPORT_BALANCE_SHEET,
      PERMISSIONS.REPORT_TRIAL_BALANCE,
      PERMISSIONS.REPORT_INCOME_STATEMENT,
      PERMISSIONS.REPORT_AGEING,
      PERMISSIONS.REPORT_ARREARS,
      PERMISSIONS.REPORT_STATUS,
      PERMISSIONS.REPORT_DISBURSEMENT,
      PERMISSIONS.REPROT_PORTFOLIO,
      PERMISSIONS.REPORT_CUMMULATIVE_REPAYMENTS,
      PERMISSIONS.REPORT_LOAN,
      PERMISSIONS.REPORT_MEMBERSHIP,
      PERMISSIONS.REPORT_EXPENSE,
      PERMISSIONS.REPORT_FEES,
      PERMISSIONS.REPORT_OFFICER,
      PERMISSIONS.REPORT_SAVINGS,
      PERMISSIONS.REPORT_PHYSICAL_ASSET,
      PERMISSIONS.REPORT_CUMULATE_SAVINGS,
      PERMISSIONS.REPORT_SAVINGS_OFFICER,
      PERMISSIONS.REPORT_SUMMARY_COLLECTION,
      PERMISSIONS.REPORT_LOAN_BOOK,
      PERMISSIONS.REPORT_PHYSICAL_ASSET,
    ],
    subMenu: [
      {
        isGroup: true,
        title: "Financial Reports",
        path: "financial-report-group",
        permissions: [
          PERMISSIONS.REPORT_BALANCE_SHEET,
          PERMISSIONS.REPORT_TRIAL_BALANCE,
          PERMISSIONS.REPORT_INCOME_STATEMENT,
          PERMISSIONS.REPORT_EXPENSE,
          PERMISSIONS.REPORT_FEES,
        ],
        menuItems: [
          {
            path: "/reports/balance-sheet",
            title: "Balance Sheet",
            permissions: [PERMISSIONS.REPORT_BALANCE_SHEET],
          },
          {
            path: "/reports/trial-balance",
            title: "Trial Balance",
            permissions: [PERMISSIONS.REPORT_TRIAL_BALANCE],
          },
          {
            path: "/reports/income-statement",
            title: "Income Statement",
            permissions: [PERMISSIONS.REPORT_INCOME_STATEMENT],
          },
          {
            path: "/reports/cashflow-statement",
            title: "Cashflow Statement",
            permissions: [PERMISSIONS.REPORT_CASHFLOW_STATEMENT],
          },
          {
            path: "/reports/expenses-report",
            title: "Expenses Report",
            permissions: [PERMISSIONS.REPORT_EXPENSE],
          },
          {
            path: "/reports/fees-report",
            title: "Fees Report",
            permissions: [PERMISSIONS.REPORT_FEES],
          },
        ],
      },
      {
        isGroup: true,
        title: "Loan Reports",
        path: "loan-reports-group",
        permissions: [
          PERMISSIONS.REPORT_AGEING,
          PERMISSIONS.REPORT_ARREARS,
          PERMISSIONS.REPORT_STATUS,
          PERMISSIONS.REPORT_DISBURSEMENT,
          PERMISSIONS.REPROT_PORTFOLIO,
          PERMISSIONS.REPORT_CUMMULATIVE_REPAYMENTS,
          PERMISSIONS.REPORT_LOAN_REPAYMENTS,
          PERMISSIONS.REPORT_OFFICER,
          PERMISSIONS.REPORT_SUMMARY_COLLECTION,
          PERMISSIONS.REPORT_SAVINGS,
          PERMISSIONS.REPORT_LOAN_BOOK,
          PERMISSIONS.REPORT_LOAN,
          PERMISSIONS.REPORT_EXPECTED_LOAN_REPAYMENT,
        ],
        menuItems: [
          {
            path: "/reports/ageing-report",
            title: "Aging Report",
            permissions: [PERMISSIONS.REPORT_AGEING],
          },
          {
            path: "/reports/loan-arrears-report",
            title: "Loan Arrears Report",
            permissions: [PERMISSIONS.REPORT_ARREARS],
          },
          {
            path: "/reports/loan-status-report",
            title: "Loan Status Report",
            permissions: [PERMISSIONS.REPORT_STATUS],
          },
          {
            path: "/reports/loan-disbursement-report",
            title: "Loan Disbursement Report",
            permissions: [PERMISSIONS.REPORT_DISBURSEMENT],
          },
          {
            path: "/reports/portfolio-at-risk-report",
            title: "Portfolio At Risk Report",
            permissions: [PERMISSIONS.REPROT_PORTFOLIO],
          },
          {
            path: "/reports/loan-repayment-report",
            title: "Cumulative Loan Repayment",
            permissions: [PERMISSIONS.REPORT_CUMMULATIVE_REPAYMENTS],
          },
          {
            path: "/reports/loan-officer-report",
            title: "Loan Officer Report",
            permissions: [PERMISSIONS.REPORT_OFFICER],
          },
          {
            path: "/reports/loan-officer-summary-report",
            title: "Loan Officer Summary Report",
            permissions: [PERMISSIONS.REPORT_OFFICER],
          },
          {
            path: "/reports/summary-collection-report",
            title: "Summary Collection",
            permissions: [PERMISSIONS.REPORT_SUMMARY_COLLECTION],
          },
          {
            path: "/reports/par-aging-report",
            title: "Par Aging Report",
            permissions: [PERMISSIONS.REPORT_AGEING],
          },
          {
            path: "/reports/loan-book-report",
            title: "Loan Book Report",
            permissions: [PERMISSIONS.REPORT_LOAN_BOOK],
          },
          {
            path: "/reports/expected-loan-repayment-report",
            title: "Expected Loan Repayment Report",
            permissions: [PERMISSIONS.REPORT_EXPECTED_LOAN_REPAYMENT],
          },
          {
            path: "/reports/loan-repayment-transaction-report",
            title: "Loan Repayment Report",
            permissions: [PERMISSIONS.REPORT_LOAN_REPAYMENTS],
          },
        ],
      },
      {
        isGroup: true,
        title: "Savings Reports",
        path: "savings-report-group",
        permissions: [
          PERMISSIONS.REPORT_SAVINGS,
          PERMISSIONS.REPORT_CUMULATE_SAVINGS,
          PERMISSIONS.REPORT_SAVINGS_OFFICER,
        ],
        menuItems: [
          {
            path: "/reports/savings-report",
            title: "Savings Report",
            permissions: [PERMISSIONS.REPORT_SAVINGS],
          },
          {
            path: "/reports/cumulative-savings-report",
            title: "Cumulative Savings Report",
            permissions: [PERMISSIONS.REPORT_CUMULATE_SAVINGS],
          },
          {
            path: "/reports/savings-officer-report",
            title: "Savings Officer Report",
            permissions: [PERMISSIONS.REPORT_SAVINGS_OFFICER],
          },
        ],
      },
      {
        isGroup: true,
        title: "Client Reports",
        path: "client-report-group",
        permissions: [PERMISSIONS.REPORT_MEMBERSHIP, PERMISSIONS.REPORT_LOAN],
        menuItems: [
          {
            path: "/reports/membership-schedule",
            title: "Shareholder Report",
            permissions: [PERMISSIONS.REPORT_MEMBERSHIP],
          },
          {
            path: "/reports/client-report",
            title: "Client Report",
            permissions: [PERMISSIONS.REPORT_LOAN],
          },
        ],
      },
      {
        isGroup: true,
        title: "Asset Reports",
        path: "physical-asset-report",
        menuItems: [
          {
            path: "/reports/physical-asset-report",
            title: "Physical Asset Report",
            permissions: [PERMISSIONS.REPORT_PHYSICAL_ASSET],
          },
          {
            path: "/reports/physical-asset-sale-report",
            title: "Physical Asset Sale Report",
            permissions: [PERMISSIONS.REPORT_PHYSICAL_ASSET_SALES],
          },
          {
            path: "/reports/non-physical-asset-report",
            title: "Non Physical Asset Report",
            permissions: [PERMISSIONS.REPORT_NON_PHYSICAL_ASSETS],
          },
        ],
      },
    ],
  },
  {
    title: "System Settings",
    Icon: Icons.AdminPanelSettingsIcon,
    path: "/settings",
    users: [USER_TYPES.INSTITUTION_ADMIN, USER_TYPES.STAFF],
    permissions: [
      PERMISSIONS.ADD_BRANCH,
      PERMISSIONS.VIEW_BRANCH,
      PERMISSIONS.ADD_ROLES,
      PERMISSIONS.VIEW_ROLES,
      PERMISSIONS.ADD_STAFF,
      PERMISSIONS.VIEW_STAFF,
      PERMISSIONS.VIEW_LOAN_FEES,
      PERMISSIONS.VIEW_TRANSACTION_FEES,
      PERMISSIONS.VIEW_ACCOUNT_OPENING_FEES,
      PERMISSIONS.VIEW_LOAN_PRODUCTS,
      PERMISSIONS.VIEW_COLLATERAL_CATEGORIES,
      PERMISSIONS.VIEW_WORKING_HOURS_BRANCH,
      PERMISSIONS.VIEW_WORKING_HOURS_ROLE,
    ],
    subMenu: [
      {
        path: "/settings/bank-settings",
        title: "Bank Settings",
        users: [USER_TYPES.INSTITUTION_ADMIN],
      },
      {
        path: "/settings/branches",
        title: "Branches",
        permissions: [PERMISSIONS.ADD_BRANCH, PERMISSIONS.VIEW_BRANCH],
      },
      {
        path: "/settings/workflows",
        title: "Workflows",
        permissions: [PERMISSIONS.ADD_BRANCH, PERMISSIONS.VIEW_BRANCH],
      },
      {
        path: "/settings/roles-and-permissions",
        title: "Roles & Permissions",
        permissions: [PERMISSIONS.ADD_ROLES, PERMISSIONS.VIEW_ROLES],
      },
      {
        path: "/settings/staff",
        title: "Staff",
        permissions: [PERMISSIONS.ADD_STAFF, PERMISSIONS.VIEW_STAFF],
      },
      {
        path: "/settings/fees-settings",
        title: "Fees Settings",
        permissions: [
          PERMISSIONS.VIEW_LOAN_FEES,
          PERMISSIONS.ADD_LOAN_FEES,
          PERMISSIONS.VIEW_TRANSACTION_FEES,
          PERMISSIONS.ADD_TRANSACTION_FEES,
          PERMISSIONS.VIEW_ACCOUNT_OPENING_FEES,
          PERMISSIONS.ADD_ACCOUNT_OPENING_FEES,
        ],
      },
      {
        path: "/settings/loan-settings",
        title: "Loan Settings",
        permissions: [
          PERMISSIONS.VIEW_LOAN_PRODUCTS,
          PERMISSIONS.ADD_LOAN_PRODUCTS,
          PERMISSIONS.VIEW_COLLATERAL_CATEGORIES,
          PERMISSIONS.ADD_COLLATERAL_CATEGORIES,
        ],
      },
      {
        path: "/settings/savings-settings",
        title: "Savings Settings",
        permissions: [
          PERMISSIONS.VIEW_SAVINGS_PRODUCTS,
          PERMISSIONS.ADD_SAVINGS_PRODUCTS,
        ],
      },
      {
        path: "/settings/working-hours",
        title: "Working Hours",
        permissions: [
          PERMISSIONS.VIEW_WORKING_HOURS_BRANCH,
          PERMISSIONS.ADD_WORKING_HOURS_BRANCH,
          PERMISSIONS.EDIT_WORKING_HOURS_BRANCH,
          PERMISSIONS.VIEW_WORKING_HOURS_ROLE,
          PERMISSIONS.ADD_WORKING_HOURS_ROLE,
          PERMISSIONS.EDIT_WORKING_HOURS_ROLE,
        ],
      },
      {
        path: "/settings/general-settings",
        title: "General Settings",
        users: [USER_TYPES.INSTITUTION_ADMIN],
      },
    ],
  },
  {
    title: "Survey",
    Icon: Icons.AddReactionIcon,
    path: "/survey",
    users: [USER_TYPES.INSTITUTION_ADMIN, USER_TYPES.STAFF],
    permissions: [],
    subMenu: [
      {
        title: "Templates",
        path: "/survey/survey-templates",
        permissions: [],
        users: [USER_TYPES.INSTITUTION_ADMIN, USER_TYPES.STAFF],
      },
    ],
  },
  {
    title: "Trash",
    Icon: Icons.DeleteIcon,
    path: "/trash",
    users: [USER_TYPES.INSTITUTION_ADMIN, USER_TYPES.STAFF],
    permissions: [],
    subMenu: [
      {
        isGroup: true,
        title: "Clients",
        path: "deleted-clients",
        permissions: [],
        menuItems: [
          {
            path: "/trash/deleted-clients-individual",
            title: "Individual",
            permissions: [],
          },
          {
            path: "/trash/deleted-clients-group",
            title: "Group",
            permissions: [],
          },
          {
            path: "/trash/deleted-clients-institution",
            title: "Institution",
            permissions: [],
          },
        ],
      },
      {
        isGroup: true,
        title: "Loan products",
        path: "deleted-loan-products",
        permissions: [],
        menuItems: [
          {
            path: "/trash/deleted-loan-products",
            title: "Loan products",
            permissions: [],
          },
        ],
      },
      {
        isGroup: true,
        title: "Savings Products",
        path: "deleted-savings-products",
        permissions: [],
        menuItems: [
          {
            path: "/trash/deleted-savings-products",
            title: "Savings Products",
            permissions: [],
          },
        ],
      },
      {
        isGroup: true,
        title: "Accounts",
        path: "deleted-accounts",
        permissions: [],
        menuItems: [
          {
            path: "/trash/deleted-accounts",
            title: "Accounts",
            permissions: [],
          },
        ],
      },
    ],
  },

  // SYSTEM USER ONLY
  {
    title: "Institutions",
    Icon: Icons.AccountBalanceIcon,
    path: "/all-banks",
    users: [USER_TYPES.SYSTEM_USER],
    permissions: [],
  },
  {
    title: "Institution Admins",
    Icon: Icons.SupervisorAccountIcon,
    path: "/all-banks-admin",
    users: [USER_TYPES.SYSTEM_USER],
    permissions: [],
  },
  {
    title: "Master Admins",
    Icon: Icons.SupervisorAccountIcon,
    path: "/master-admin",
    users: [USER_TYPES.SYSTEM_USER],
    permissions: [],
  },
  {
    title: "KYC Settings",
    Icon: Icons.PersonSearchIcon,
    path: "/kyc-settings",
    users: [USER_TYPES.SYSTEM_USER],
    permissions: [],
  },
];

export default MenuList;
