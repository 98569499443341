export const PERMISSIONS = {
  // clients
  ADD_CLIENTS: "zimba_clients.add_client",
  VIEW_CLIENTS: "zimba_clients.view_client",
  EDIT_CLIENTS: "zimba_clients.change_client",
  ADD_CLIENT_BUSINESS: "zimba_clients.add_business",
  CONVERT_NON_MEMBER_TO_MEMBER: "zimba_clients.convert_non_member_to_member",
  ADD_GROUP_MEMBERS: "zimba_clients.add_clientmembership",
  DELETE_GROUP_MEMBERS: "zimba_clients.delete_clientmembership",
  EXEMPT_RECURRING_FEES: "zimba_clients.can_exempt_recurring_fees",
  EXEMPT_OPENING_FEES: "zimba_clients.can_exempt_account_opening_fees",
  EXEMPT_DEPOSIT_FEES: "zimba_clients.can_exempt_deposit_fees",
  EXEMPT_WITHDRAW_FEES: "zimba_clients.can_exempt_withdraw_fees",
  APPROVE_CLIENT_WORKFLOWS: "zimba_clients.approve_client_workflows",
  DECLINE_CLIENT_WORKFLOWS: "zimba_clients.decline_client_workflows",

  ADD_BENEFICIARY: "zimba_clients.add_beneficiary",
  VIEW_BENEFICIARY: "zimba_clients.view_beneficiary",
  EDIT_BENEFICIARY: "zimba_clients.change_beneficiary",
  DELETE_BENEFICIARY: "zimba_clients.delete_beneficiary",

  //employer
  DELETE_EMPLOYER: "zimba_clients.delete_employer",
  EDIT_EMPLOYER: "zimba_clients.change_employer",
  ADD_EMPLOYER: "zimba_clients.add_employer",

  // savings
  ADD_ACCOUNT: "zimba_clients.add_clientaccount",
  CHANGE_CLIENT_ACCOUNT: "zimba_clients.change_clientaccount",
  MANAGE_SAVINGS: "zimba_clients.manage_savings",
  CLIENT_ADD_DEPOSIT: "zimba_clients.add_deposit",
  CLIENT_ADD_WITHDRAWAL: "zimba_clients.add_withdraw",
  CLIENT_ADD_FIXEDDEPOSIT: "zimba_clients.add_fixeddeposit",
  CLIENT_MAKE_ACCOUNT_TRANSFER: "zimba_clients.make_account_transfer",
  DISRBUSE_INTEREST: "zimba_clients.can_credit_interest",
  VIEW_CLIENT_CREDIT_HISTORY: "zimba_clients.client_credit_history",

  // chart of accounts
  VIEW_CHART_OF_ACCOUNTS: "zimba_accounts.view_account",
  ADD_CHART_OF_ACCOUNTS: "zimba_accounts.add_account",
  EDIT_CHART_OF_ACCOUNTS: "zimba_accounts.change_account",
  DELETE_CHART_OF_ACCOUNTS: "zimba_accounts.delete_account",

  VIEW_JOURNAL_ENTRY: "zimba_accounts.view_journalentry",
  ADD_JOURNAL_ENTRY: "zimba_accounts.add_journalentry",
  CLEAR_PAYABLE: "zimba_accounts.clear_payable",
  COLLECT_RECEIVABLE: "zimba_accounts.collect_receivable",
  VIEW_TILL_SHEET: "zimba_accounts.view_tillsheet",

  VIEW_PAYABLES: "zimba_accounts.view_payables",
  ADD_PAYABLES: "zimba_accounts.add_payables",
  EDIT_PAYABLES: "zimba_accounts.change_payables",
  DELETE_PAYABLES: "zimba_accounts.delete_payables",

  VIEW_CREDITORS: "zimba_accounts.view_creditors",
  ADD_CREDITORS: "zimba_accounts.add_creditors",
  EDIT_CREDITORS: "zimba_accounts.change_creditors",
  DELETE_CREDITORS: "zimba_accounts.delete_creditors",

  VIEW_RECEIVABLES: "zimba_accounts.view_receivables",
  ADD_RECEIVABLES: "zimba_accounts.add_receivables",
  EDIT_RECEIVABLES: "zimba_accounts.change_receivables",
  DELETE_RECEIVABLES: "zimba_accounts.delete_receivables",

  VIEW_DEBTORS: "zimba_accounts.view_debtors",
  ADD_DEBTORS: "zimba_accounts.add_debtors",
  EDIT_DEBTORS: "zimba_accounts.change_debtors",
  DELETE_DEBTORS: "zimba_accounts.delete_debtors",

  VIEW_TAX_PAYMENTS: "zimba_accounts.view_debtors",
  ADD_TAX_PAYMENTS: "zimba_accounts.add_tax_paymentsrs",
  EDIT_TAX_PAYMENTS: "zimba_accounts.change_debtors",
  DELETE_TAX_PAYMENTS: "zimba_accounts.delete_debtors",

  VIEW_SUPPLIERS: "zimba_instutitions.view_suppliers",
  ADD_SUPPLIERS: "zimba_instutitions.add_suppliers",
  EDIT_SUPPLIERS: "zimba_instutitions.change_suppliers",
  DELETE_SUPPLIERS: "zimba_instutitions.delete_suppliers",

  // loans
  VIEW_LOANS: "zimba_loans.view_loan",
  ADD_LOANS: "zimba_loans.add_loan",
  EDIT_LOANS: "zimba_loans.change_loan",
  DELETE_LOANS: "zimba_loans.delete_loan",
  APPROVE_LOANS: "zimba_loans.approve_loan",
  DISBURSE_LOANS: "zimba_loans.disburse_loan",
  WRITE_OFF_LOANS: "zimba_loans.write_off_loan",
  DECLINE_LOANS: "zimba_loans.decline_loan",
  MAKE_REPAYMENTS: "zimba_loans.make_repayment",
  LOAN_RESCHEDULE: "zimba_loans.add_loanschedulemap",
  WAIVE_INTEREST: "zimba_loans.waive_interest",
  WAIVE_PANALTY: "zimba_loans.waive_penalty",
  MAKE_TOPUP: "zimba_loans.make_top_up",
  MAKE_PAYOFF: "zimba_loans.make_early_pay_off",

  VIEW_LOAN_COLLATERALS: "zimba_loans.view_collaterals",
  ADD_LOAN_COLLATERALS: "zimba_loans.add_collaterals",

  VIEW_GUARANTORS: "zimba_loans.view_guarantors",
  ADD_GUARANTORS: "zimba_loans.add_guarantors",
  EXEMPT_FEES_UPFRONT: "zimba_loans.can_exempt_loan_upfront_fees",
  EXEMPT_LOAN_DISBURSEMENT: "zimba_loans.can_exempt_loan_disbursement_fees",
  EXEMPT_LOAN_REPAYMENT: "zimba_loans.can_exempt_loan_repayment_fees",

  VIEW_CASH_TRANSFERS: "zimba_accounts.view_cash_transfers",
  ADD_CASH_TRANSFERS: "zimba_accounts.add_cash_transfers",
  CASH_SAFE_TO_TELLER: "zimba_accounts.safe_to_teller",
  CASH_SAFE_TO_SAFE: "zimba_accounts.safe_to_safe",
  CASH_SAFE_TO_MOBILE: "zimba_accounts.safe_to_mobile",
  CASH_TELLER_TO_SAFE: "zimba_accounts.teller_to_safe",
  CASH_TELLER_TO_TELLER: "zimba_accounts.teller_to_teller",
  CASH_BANK_TO_SAFE: "zimba_accounts.bank_to_safe",
  CASH_SAFE_TO_BANK: "zimba_accounts.safe_to_bank",
  CASH_BANK_TO_BANK: "zimba_accounts.bank_to_bank",
  CASH_TELLER_TO_BANK: "zimba_accounts.teller_to_bank",
  CASH_TELLER_TO_MOBILE: "zimba_accounts.teller_to_mobile",
  CASH_BANK_TO_TELLER: "zimba_accounts.bank_to_teller",
  CASH_BANK_TO_MOBILE: "zimba_accounts.bank_to_mobile",
  CASH_MOBILE_TO_TELLER: "zimba_accounts.mobile_to_teller",
  CASH_MOBILE_TO_BANK: "zimba_accounts.mobile_to_bank",
  CASH_MOBILE_TO_SAFE: "zimba_accounts.mobile_to_safe",
  CASH_MOBILE_TO_MOBILE: "zimba_accounts.mobile_to_mobile",

  VIEW_ACCOUNTS: "zimba_accounts.view_account",
  ADD_STAFF_CASH_ACCOUNTS: "zimba_accounts.add_staffcashaccounts",
  ADD_STAFF_BANK_ACCOUNTS: "zimba_accounts.add_bankaccounts",
  ADD_STAFF_SAFE_ACCOUNTS: "zimba_accounts.add_safeaccounts",
  VIEW_STAFF_CASH_ACCOUNTS: "zimba_accounts.view_staffcashaccounts",
  VIEW_STAFF_BANK_ACCOUNTS: "zimba_accounts.view_bankaccounts",
  VIEW_STAFF_SAFE_ACCOUNTS: "zimba_accounts.view_safeaccounts",
  DELETE_STAFF_CASH_ACCOUNTS: "zimba_accounts.delete_staffcashaccounts",
  DELETE_STAFF_BANK_ACCOUNTS: "zimba_accounts.delete_bankaccounts",
  DELETE_STAFF_SAFE_ACCOUNTS: "zimba_accounts.delete_safeaccounts",
  EDIT_STAFF_CASH_ACCOUNTS: "zimba_accounts.change_staffcashaccounts",
  EDIT_STAFF_BANK_ACCOUNTS: "zimba_accounts.change_bankaccounts",
  EDIT_STAFF_SAFE_ACCOUNTS: "zimba_accounts.change_safeaccounts",
  VIEW_STAFF_BANK_ACCOUNT_STATEMENT:
    "zimba_accounts.view_bank_account_statement",
  VIEW_STAFF_SAFE_ACCOUNT_STATEMENT:
    "zimba_accounts.view_safe_accounts_statements",
  VIEW_TRANSACTION_ACCOUNTS_SUMMARY:
    "zimba_accounts.view_transaction_accounts_summary",
  VIEW_STAFF_MOBILE_MONEY_ACCOUNTS: "zimba_accounts.view_mobileaccounts",
  ADD_STAFF_MOBILE_MONEY_ACCOUNTS: "zimba_accounts.add_mobileaccounts",
  EDIT_STAFF_MOBILE_MONEY_ACCOUNTS: "zimba_accounts.change_mobileaccounts",
  DELETE_STAFF_MOBILE_MONEY_ACCOUNTS: "zimba_accounts.delete_mobileaccounts",
  VIEW_STAFF_MOBILE_MONEY_ACCOUNT_STATEMENT:
    "zimba_accounts.view_mobile_accounts_statements",

  // permissions for data importer
  IMPORT_CLIENTS: "zimba_utils.add_dataimporterclients",
  IMPORT_LOANS: "zimba_utils.add_dataimporterloanrecords",
  IMPORT_CLIENT_ACCOUNTS: "zimba_utils.add_dataimporterclientaccounts",
  IMPORT_CHART_OF_ACCOUNTS: "zimba_utils.add_dataimporterchartaccounts",
  IMPORT_SHARES: "zimba_utils.add_dataimportershares",

  ADD_BATCH_IMPORT: "zimba_utils.add_dataimporterbatch",
  VIEW_BATCH_IMPORT: "zimba_utils.view_dataimporterbatch",
  DELETE_BATCH_IMPORT: "zimba_utils.delete_dataimporterbatch",

  VIEW_IMPORT_RECORDS_CLIENTS: "zimba_utils.view_dataimporterclients",
  VIEW_IMPORT_RECORDS_LOANS: "zimba_utils.view_dataimporterloanrecords",
  VIEW_IMPORT_RECORDS_CLIENT_ACCOUNTS:
    "zimba_utils.view_dataimporterclientaccounts",
  VIEW_IMPORT_RECORDS_CHART_OF_ACCOUNTS:
    "zimba_utils.view_dataimporterchartaccounts",
  VIEW_IMPORT_RECORDS_SHARES: "zimba_utils.view_dataimportershares",

  // shares
  VIEW_SHARES: "zimba_shares.view_shareregister",
  ADD_PURCHASE_SHARES: "zimba_shares.add_sharepurchases",
  ADD_TRANSFER_SHARES: "zimba_shares.add_sharetransfers",
  VIEW_PURCHASE_SHARES: "zimba_shares.add_sharepurchases",
  VIEW_TRANSFER_SHARES: "zimba_shares.add_sharetransfers",
  MAKE_DIVIDEND_PAYOUT: "zimba_shares.make_dividends_payouts",
  ADD_DIVIDEND_PAYABLE: "zimba_shares.add_dividendspayable",
  APPROVE_DIVIDEND_PAYABLE: "zimba_shares.approve_dividends_payable",
  EDIT_DIVIDEND_PAYABLE: "zimba_shares.change_dividendspayable",
  DELETE_DIVIDEND_PAYABLE: "zimba_shares.delete_dividendspayable",
  VIEW_DIVIDEND_PAYABLE: "zimba_shares.delete_dividendspayable",

  // reports
  REPORT_BALANCE_SHEET: "zimba_accounts.view_balance_sheet",
  REPORT_TRIAL_BALANCE: "zimba_accounts.view_trial_balance",
  REPORT_INCOME_STATEMENT: "zimba_accounts.view_income_statement",
  REPORT_CASHFLOW_STATEMENT: "zimba_accounts.view_cashflow_statement",
  REPORT_AGEING: "zimba_loans.view_aging_report",
  REPORT_ARREARS: "zimba_loans.view_arrears_report",
  REPORT_STATUS: "zimba_loans.view_status_report",
  REPORT_DISBURSEMENT: "zimba_loans.view_disbursement_report",
  REPROT_PORTFOLIO: "zimba_loans.view_portifolio_report",
  REPORT_CUMMULATIVE_REPAYMENTS: "zimba_loans.view_repayment_report",
  REPORT_LOAN_REPAYMENTS: "zimba_accounts.view_loan_repayments_report",
  REPORT_LOAN: "zimba_clients.view_loan_report",
  REPORT_LOAN_BOOK: "zimba_loans.view_loan_book_report",
  REPORT_MEMBERSHIP: "zimba_clients.view_membership_report",
  REPORT_EXPENSE: "zimba_accounts.view_expense_report",
  REPORT_FEES: "zimba_accounts.view_fees_report",
  REPORT_OFFICER: "zimba_loans.view_credit_officer_report",
  REPORT_SAVINGS: "zimba_accounts.view_savings_report",
  REPORT_CUMULATE_SAVINGS: "zimba_clients.view_cumulative_report",
  REPORT_SAVINGS_OFFICER: "zimba_clients.view_savings_officer_report",
  REPORT_SUMMARY_COLLECTION: "zimba_loans.view_summary_collection_report",
  REPORT_PHYSICAL_ASSET: "zimba_assets.view_physical_assets_report",
  REPORT_PHYSICAL_ASSET_SALES: "zimba_assets.view_physical_assets_sales_report",
  REPORT_NON_PHYSICAL_ASSETS: "zimba_assets.view_non_physical_assets_report",
  REPORT_EXPECTED_LOAN_REPAYMENT:
    "zimba_loans.view_expected_loan_payments_report",

  // permissions for settings
  ADD_BRANCH: "zimba_institutions.add_branch",
  VIEW_BRANCH: "zimba_institutions.view_branch",
  DELETE_BRANCH: "zimba_institutions.delete_branch",
  EDIT_BRANCH: "zimba_institutions.change_branch",

  ADD_ROLES: "zimba_auth.add_institutiongroup",
  VIEW_ROLES: "zimba_auth.view_institutiongroup",
  DELETE_ROLES: "zimba_auth.delete_institutiongroup",
  EDIT_ROLES: "zimba_auth.change_institutiongroup",

  VIEW_LOAN_FEES: "zimba_settings.view_fee",
  ADD_LOAN_FEES: "zimba_settings.add_fee",
  DELETE_LOAN_FEES: "zimba_settings.delete_fee",
  EDIT_LOAN_FEES: "zimba_settings.change_fee",

  VIEW_LOAN_PRODUCTS: "zimba_settings.view_loantypes",
  ADD_LOAN_PRODUCTS: "zimba_settings.add_loantypes",
  DELETE_LOAN_PRODUCTS: "zimba_settings.delete_loantypes",
  EDIT_LOAN_PRODUCTS: "zimba_settings.change_loantypes",

  VIEW_TRANSACTION_FEES: "zimba_settings.view_transactioncharges",
  ADD_TRANSACTION_FEES: "zimba_settings.add_transactioncharges",
  DELETE_TRANSACTION_FEES: "zimba_settings.delete_transactioncharges",
  EDIT_TRANSACTION_FEES: "zimba_settings.change_transactioncharges",

  VIEW_ACCOUNT_OPENING_FEES: "zimba_settings.view_accountopeningfees",
  ADD_ACCOUNT_OPENING_FEES: "zimba_settings.add_accountopeningfees",
  DELETE_ACCOUNT_OPENING_FEES: "zimba_settings.delete_accountopeningfees",
  EDIT_ACCOUNT_OPENING_FEES: "zimba_settings.change_accountopeningfees",

  VIEW_COLLATERAL_CATEGORIES: "zimba_loans.view_collateralcategories",
  ADD_COLLATERAL_CATEGORIES: "zimba_loans.add_collateralcategories",
  DELETE_COLLATERAL_CATEGORIES: "zimba_loans.delete_collateralcategories",
  EDIT_COLLATERAL_CATEGORIES: "zimba_loans.change_collateralcategories",

  VIEW_SAVINGS_PRODUCTS: "zimba_accounts.view_savingaccounts",
  ADD_SAVINGS_PRODUCTS: "zimba_accounts.add_savingaccounts",
  DELETE_SAVINGS_PRODUCTS: "zimba_accounts.delete_savingaccounts",
  EDIT_SAVINGS_PRODUCTS: "zimba_accounts.change_savingaccounts",

  VIEW_WORKFLOW: "zimba_workflows.view_workflow",
  ADD_WORKFLOW: "zimba_workflows.add_workflow",
  DELETE_WORKFLOW: "zimba_workflows.delete_workflow",
  EDIT_WORKFLOW: "zimba_workflows.change_workflow",

  VIEW_WORKFLOW_LEVEL: "zimba_workflows.view_workflowlevel",
  ADD_WORKFLOW_LEVEL: "zimba_workflows.add_workflowlevel",
  DELETE_WORKFLOW_LEVEL: "zimba_workflows.delete_workflowlevel",
  EDIT_WORKFLOW_LEVEL: "zimba_workflows.change_workflowlevel",

  VIEW_WORKFLOW_REQUEST: "zimba_workflows.view_workflowrequest",
  ADD_WORKFLOW_REQUEST: "zimba_workflows.add_workflowrequest",
  DELETE_WORKFLOW_REQUEST: "zimba_workflows.delete_workflowrequest",
  EDIT_WORKFLOW_REQUEST: "zimba_workflows.change_workflowrequest",

  VIEW_WORKFLOW_TASK: "zimba_workflows.view_workflowtask",
  ADD_WORKFLOW_TASK: "zimba_workflows.add_workflowtask",
  DELETE_WORKFLOW_TASK: "zimba_workflows.delete_workflowtask",
  EDIT_WORKFLOW_TASK: "zimba_workflows.change_workflowtask",

  VIEW_RECURRING_FEES: "zimba_settings.view_recurringfees",
  ADD_RECURRING_FEES: "zimba_settings.add_recurringfees",
  DELETE_RECURRING_FEES: "zimba_settings.delete_recurringfees",
  EDIT_RECURRING_FEES: "zimba_settings.change_recurringfees",

  SHARES_SETTINGS: "zimba_institutions.manage_share_settings",

  // staff
  ADD_STAFF: "zimba_auth.add_staff",
  VIEW_STAFF: "zimba_auth.view_staff",
  EDIT_STAFF: "zimba_auth.change_staff",
  DELETE_STAFF: "zimba_auth.delete_staff",
  EXPORT_STAFF_AUDIT: "zimba_auth.request_for_audit_trail",

  // hours
  VIEW_WORKING_HOURS_BRANCH: "zimba_settings.view_workinghoursbranch",
  ADD_WORKING_HOURS_BRANCH: "zimba_settings.add_workinghoursbranch",
  EDIT_WORKING_HOURS_BRANCH: "zimba_settings.change_workinghoursbranch",

  VIEW_WORKING_HOURS_ROLE: "zimba_settings.view_branchroleworkinghours",
  ADD_WORKING_HOURS_ROLE: "zimba_settings.add_branchroleworkinghours",
  EDIT_WORKING_HOURS_ROLE: "zimba_settings.change_branchroleworkinghours",

  // sms settings
  MANAGE_SMS_SETTINGS: "zimba_institutions.manage_sms_settings",
  VIEW_OUTBOX: "zimba_sms.view_smsoutbox",
  ADD_SMS_OUTBOX: "zimba_sms.add_smsoutbox",
  VIEW_SCHEDULED_SMS: "zimba_sms.view_scheduledsms",
  ADD_SCHEDULED_SMS: "zimba_sms.add_scheduledsms",

  VIEW_SMS_TEMPLATES: "zimba_sms.view_smstypes",
  ADD_SMS_TEMPLATES: "zimba_sms.add_smstypes",
  EDIT_SMS_TEMPLATES: "zimba_sms.change_smstypes",
  DELETE_SMS_TEMPLATES: "zimba_sms.delete_smstypes",

  // utils
  GENERAL_ATTACHMENTS: "zimba_utils.add_attachment",

  // provisions
  VIEW_PROVISIONS: "zimba_settings.view_loanprovisionrange",
  ADD_PROVISIONS: "zimba_settings.add_loanprovisionrange",
  EDIT_PROVISIONS: "zimba_settings.change_loanprovisionrange",
  DELETE_PROVISIONS: "zimba_settings.delete_loanprovisionrange",

  // BRANCH ACCESS
  CROSS_BRANCH_ACCESS: "zimba_auth.grant_cross_branch_access",
  // Tasks
  VIEW_TODOS: "zimba_institutions.view_todotasks",
  EDIT_TODOS: "zimba_institutions.change_todotasks",
  // Assets
  VIEW_PHYSICAL_ASSETS: "zimba_assets.view_physicalasset",
  ADD_PHYSICAL_ASSETS: "zimba_assets.add_physicalasset",
  EDIT_PHYSICAL_ASSETS: "zimba_assets.change_physicalasset",
  DELETE_PHYSICAL_ASSETS: "zimba_assets.delete_physicalasset",
  VIEW_MAINTENANCE_SCEDULE: "zimba_assets.view_maintenanceschedule",
  ADD_MAINTENANCE_SCEDULE: "zimba_assets.add_maintenanceschedule",
  ADD_SALE_ASSETS: "zimba_assets.add_assetsale",
  VIEW_SALE_ASSETS: "zimba_assets.view_assetsale",
  ADD_NON_PHYSICAL_ASSETS: "zimba_assets.add_nonphysicalasset",
  VIEW_NON_PHYSICAL_ASSETS: "zimba_assets.view_nonphysicalasset",
  EDIT_NON_PHYSICAL_ASSETS: "zimba_assets.change_nonphysicalasset",
  DELETE_NON_PHYSICAL_ASSETS: "zimba_assets.delete_nonphysicalasset",

  // Psychometric test
  VIEW_PSYC_TEST: "zimba_auth.view_pyschometric_test_score",
  SEND_PSYC_TEST: "zimba_auth.request_pyschometric_test",
};
